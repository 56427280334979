var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "del-link",
      class: _vm.class_names,
      attrs: {
        disabled: _vm.disabled_button,
        "data-confirm-button-text": _vm.data_confirm_button_text,
        "data-heading": _vm.data_heading,
        "data-vue-component": "true",
        "data-confirm-type": "delete",
        "data-sweet-alert-confirm": _vm.data_sweet_alert_confirm,
        "data-description": _vm.data_description
      },
      on: {
        click: function($event) {
          return _vm.$emit("delete_started")
        }
      }
    },
    [_vm.use_slot ? [_vm._t("default")] : [_vm._v(_vm._s(_vm.value))]],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }