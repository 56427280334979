<template>
  <a class="del-link" :disabled='disabled_button' :class='class_names' :data-confirm-button-text='data_confirm_button_text' @click="$emit('delete_started')" :data-heading="data_heading" data-vue-component=true data-confirm-type="delete" :data-sweet-alert-confirm="data_sweet_alert_confirm" :data-description='data_description'>
    <template v-if='use_slot'>
      <slot></slot>
    </template>
    <template v-else>{{ value }}</template>
  </a>
</template>

<script>
  export default {
    name: 'destroyButton',
    props: ['value', 'data_confirm_button_text', 'data_heading', 'data_sweet_alert_confirm', 'data_description', 'class_names', 'use_slot', 'disabled_button'],
    mounted() {
      $(document).trigger('vue:sweetalert:added')
      $(this.$el).on('vue-sweet-alert-success', ()=> {
        this.$emit('input', false)
      })
      $(this.$el).on('vue-sweet-alert-dismiss', ()=> {
        this.$emit('onDismiss', false)
      })
    }
  }
</script>

<style scoped>
</style>
