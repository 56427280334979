<template>
  <div class='si-dt-parent relative' data-container='time-picker' @click.stop="focusTimePicker()">
    <div class="form-control-group form-date-group">
      <input type='text' :placeholder='placeholder' :id="element_id || '' " data-field-type='time-field' autocomplete='off' class='form-control place-holder' :name="field_name" v-model='timeValue' @focus="handleFocus()" @keydown.tab="showPicker = false" ref="timePicker">
      <!-- <label :for="element_id" class="" :class= 'time_icon_class'></label> -->
    </div>
    <div class='si-dt-picker' data-field='picker-container' :class="{ hide: !showPicker }">
      <div class='si-dt-row d-flex flex-wrap d-h-value' >
        <p class='full-width'>Hour</p>
        <div v-for='hour in hourValues' @click="hourValue = hour" :class="{ active: hourValue == hour }" class='value-hour si-dt-box'>{{ hour }}</div>
      </div>


      <div class='si-dt-row d-flex flex-wrap d-t-value'>
        <p class='full-width'>Minute</p>
        <div v-for='minute in minuteValues' @click="minuteValue = minute" :disabled='!hourValue' :class="{ active: (minuteValue == minute), disabled: !hourValue }" class='si-dt-box value-minutes'>{{ minute }}</div>
      </div>


      <div class='si-dt-row d-am-pm'>
        <div v-for='zone in zoneValues' @click="zoneValue = zone" :disabled='!minuteValue' :class="{ active: (zoneValue.toLowerCase() == zone.toLowerCase()), disabled: !minuteValue }" class='si-dt-box semibold value-zone'>{{ zone }}</div>
      </div>

      <div class='si-dt-row time-picker-apply'>
        <div :disabled="!valueComputable" @click.stop="showPicker = false" class='btn btn-primary btn-block normal'>Apply</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['field_name', 'field_value', 'placeholder', 'element_id', 'time_icon_class'],
    data: function() {
      return {
        hourValues: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
        minuteValues: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
        zoneValues: ['AM', 'PM'],
        hourValue: '',
        minuteValue: '',
        zoneValue: '',
        timeValue: this.field_value || '',
        showPicker: false
      }
    },
    watch: {
      hourValue: function(val) {
        if (!val) {
          this.minuteValue = '';
        }
        this.changeTimePickerValue();
      },
      minuteValue: function(val) {
        if (!val) {
          this.zoneValue = '';
        }
        this.changeTimePickerValue();
      },
      zoneValue: function(val) {
        this.changeTimePickerValue();
      },
      timeValue: function(val) {
        this.$emit('input', val);
        this.$emit('time_value_update', val);
        $(this.$refs.timePicker).val(val).trigger('change');
      },
    },
    computed: {
      computedTimeValue: function() {
        return this.hourValue.toString() + ':' +  this.minuteValue.toString() + ' ' + this.zoneValue.toString();
      },
      valueComputable: function() {
        return this.hourValue && this.minuteValue && this.zoneValue;
      }
    },
    methods: {
      setTimeValue(value) {
        this.hourValue = value.split(':')[0]
        this.minuteValue = (value.split(':')[1] && value.split(':')[1].split(' ')[0]) || ''
        this.zoneValue = (value.split(':')[1] && value.split(':')[1].split(' ')[1]) || ''
        this.changeTimePickerValue();
      },
      changeData(){
        $(this.$refs.timePicker).val(val).trigger('change');
      },
      handleFocus(){
        this.showPicker = true;
        this.$emit('focusIn');
      },
      focusTimePicker() {
        $(this.$refs.timePicker).focus();
      },
      changeTimePickerValue() {
        this.timeValue = this.computedTimeValue;
      },
      computeTimeValue() {
        this.hourValue = this.timeValue.split(':')[0]
        this.minuteValue = (this.timeValue.split(':')[1] && this.timeValue.split(':')[1].split(' ')[0]) || ''
        this.zoneValue = (this.timeValue.split(':')[1] && this.timeValue.split(':')[1].split(' ')[1]) || ''
      },
      resetValue() {
        this.hourValue = '';
        this.minuteValue = '';
      }
    },
    mounted() {
      var _this = this;
      this.computeTimeValue();
      $(this.$refs.timePicker).closest('form').on('reset', function() {
        _this.timeValue = _this.field_value;
        _this.computeTimeValue();
      });
      $(this.$el).on('close_timepicker', function() {
        _this.showPicker = false;
      });
      $(this.$refs.timePicker).on('change_time_value', function($event, data) {
        _this.timeValue = data;
        _this.computeTimeValue();
      });
      $(this.$refs.timePicker).on('input', function() {
        _this.computeTimeValue();
      });
      $(this.$refs.timePicker).on('click', function() {
        $('body').find("[data-container='time-picker']").not($(_this.$el)).trigger('close_timepicker');
      });
    }
  }
</script>

<style scoped>
</style>
